import { computed, reactive, ref } from "vue";
import {
  Slot,
  TherapistDetails,
  EventTypeDetails,
  PractiseDetails,
  SavedPaymentMethod,
  UserJourney,
} from "@/types";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { NewCustomerDetails, NewPatientDetails } from "../../../types";
import { getAgeFromDOBString } from "@/helpers/forms";
import { saveContactQuestionAnswer } from "@/helpers/hubspot";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
export const userTimezone = dayjs.tz.guess();
dayjs.tz.setDefault(userTimezone);
// Global UI
export const globalLoading = ref<boolean>(false);

// Global path tracking
export const routerPaths = reactive({ previous: "", current: "" });

//Onboarding Form Details.
export const onboardingFormId = ref();
export const onboardingPractise = ref({} as PractiseDetails);
export const therapistRole = computed(
  () => onboardingPractise.value?.therapistRole || "Therapist"
);
export const pageIndex = ref(0);
export const questionnaireAnswers = reactive<{ [key: string]: any }>({});
export const requiredSpecialities = ref<{ [key: number]: number }>({});
export const therapistChosenFromDirectory = ref(false);
export const bookingDisabled = ref(false);
export const userJourney = ref<UserJourney>({});
export const completedQuestions = ref<Set<string>>(new Set());
export const appliedFilters = ref<{ [key: string]: any }>({});

export const numFiltersApplied = computed(() => {
  if (!Object.keys(appliedFilters.value).length) {
    return 0;
  }
  const selectedFilterOptionsCount = Object.values(appliedFilters.value).reduce(
    (count: number, selectedOpts: Set<string | number> | number | string) => {
      if (typeof selectedOpts === "string") {
        return count + (selectedOpts ? 1 : 0);
      }
      return (count +=
        typeof selectedOpts === "number" ? 1 : selectedOpts.size);
    },
    0
  );
  return selectedFilterOptionsCount;
});

/// Appointment Details
export const selectedAppointmentTimeUTCTimestamp = ref("");
export const selectedDate = ref(-1); // 1 - 31
export const selectedFullDate = ref(""); // 2020-12-10
export const selectedMinute = ref("");
export const selectedMonth = ref(-1);
export const selectedHour = ref("");
export const selectedTherapist = ref<TherapistDetails>({} as TherapistDetails);
export const slots = ref(new Map<string, Slot[]>());
export const therapistSelectOptions = ref<number[]>([]);
export const eventTypeData = ref<EventTypeDetails>({} as EventTypeDetails);
export const practitionerData = ref<{ [key: number]: TherapistDetails }>({});
export const availablePractitioners = ref<TherapistDetails[]>([]);
export const appointmentSlotFilter = ref("");
export const hubspotSelectedTherapistId = ref("");
export const hubspotSelectedAppointmentTime = ref("");
export const lastPath = ref("");

// Registration Details
export const customerId = ref("");
export const patientId = ref("");
export const userId = ref("");
export const practiseId = ref(-1);
export const paymentMethodId = ref("");
export const paymentMethodDetails = ref({});
export const patientDetails = ref<NewPatientDetails>({} as NewPatientDetails);
export const customerDetails = ref<NewCustomerDetails>(
  {} as NewCustomerDetails
);
export const savedPaymentMethod = ref<SavedPaymentMethod>(
  {} as SavedPaymentMethod
);

export const storeQuestionAnswer = (
  id: string,
  answer: string | (string | number)[]
) => {
  questionnaireAnswers[id] = answer;
  completedQuestions.value.add(id);
  if (customerDetails.value.email || id === "email") {
    saveContactQuestionAnswer(
      customerDetails.value.email || (answer as string),
      `${onboardingPractise.value.slug} ${id}`,
      answer
    );
  }
};

export const clearSelectedAppointmentTime = (): void => {
  selectedHour.value = "";
  selectedMinute.value = "";
};

export const resetSelectedMonth = (): void => {
  selectedMonth.value = -1;
};

export const clearSelectedDate = (): void => {
  selectedDate.value = -1;
};

export const patientAge = computed(() => {
  if (!patientDetails.value.dateOfBirth) {
    return 0;
  }
  return getAgeFromDOBString(patientDetails.value.dateOfBirth);
});

export const selectedAppointmentTimeLabel = computed(() =>
  dayjs(selectedAppointmentTimeUTCTimestamp.value).format("hh:mm a  ")
);

export const appointmentDetails = computed(() => {
  const eventType: EventTypeDetails =
    onboardingPractise.value.defaultEventType || ({} as EventTypeDetails);
  //eslint-disable-next-line
  return {
    title: eventType.title,
    formattedLength: getFormattedLengthString(eventType.length),
    cost: getFormattedCost(eventType.default_base_cost),
    description: eventType.description,
    therapistName: selectedTherapist.value.first_name,
    therapistFullName: `${selectedTherapist.value.first_name} ${
      selectedTherapist.value.last_name || ""
    }`,
    therapistPic: selectedTherapist.value.profile_pic_url,
    startTime: `${dayjs(selectedAppointmentTimeUTCTimestamp.value).format(
      "ddd MMM D YYYY"
    )} at ${selectedAppointmentTimeLabel.value}`,
    startTimeUTC: selectedAppointmentTimeUTCTimestamp.value,
    timeZoneLabel: dayjs(selectedAppointmentTimeUTCTimestamp.value).format(
      "(zzz)"
    ),
  };
});

function getFormattedLengthString(length: string) {
  const [hours, minutes] = length.split(":");
  let sessionLeeway = 0;
  if (onboardingPractise.value.sessionLeeway) {
    sessionLeeway = onboardingPractise.value.sessionLeeway;
  }
  return `${
    parseInt(hours, 10) * 60 + parseInt(minutes, 10) - sessionLeeway
  } minutes`;
}

function getFormattedCost(numCredits: string) {
  const totalCost =
    onboardingPractise.value.creditCost * parseFloat(numCredits);
  return totalCost.toFixed(2);
}
