// Libs
import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import { pageview } from "vue-gtag";
import logger from "@/helpers/logger";
import { routerPaths, customerDetails } from "@/store";
import { updateContactDetails } from "@/helpers/hubspot";

const lazyLoad = (pagePath: string) => () =>
  import(/*webpackChunkName: "[request]"*/ `@/pages/${pagePath}.vue`);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/page-not-found",
  },

  {
    path: "/page-not-found",
    component: lazyLoad("NotFound"),
    beforeEnter(to, from, next) {
      // As we've updated vue router to HTML5 mode, this ensures redirects for any old links.
      if (to?.fullPath?.replace("/page-not-found", "")[0] === "#") {
        const newUrl = to.fullPath.replace("/page-not-found#/", "");
        next(newUrl);
        return;
      }
      next();
    },
  },
  {
    path: "/:view/:practise",
    component: lazyLoad("Main"),
    props: (route) => ({ selectedView: route.params.view || "app" }),
    children: [
      {
        path: "",
        redirect: { name: "therapist-directory" },
      },

      {
        path: "therapist-directory",
        name: "therapist-directory",
        component: lazyLoad("TherapistDirectory"),
      },
      {
        path: "therapist-directory/:therapistId",
        name: "therapist-directory-profile",
        component: lazyLoad("TherapistProfile"),
        props: true,
      },

      {
        path: "confirm-therapist",
        name: "confirm-chosen-therapist",
        component: lazyLoad("ConfirmChosenTherapist"),
      },

      {
        path: "questions/:questionId?",
        name: "questions",
        component: lazyLoad("Questions"),
      },

      {
        path: "book-or-call-back",
        name: "book-or-call-back",
        component: lazyLoad("BookOrCallBack"),
        beforeEnter(to, from, next) {
          /*eslint-disable-next-line*/
          pageview({ page_title: to.name as string, page_path: to.path });
          next();
        },
      },

      {
        path: "book-call-back",
        name: "book-call-back",
        component: lazyLoad("BookCallBack"),
        beforeEnter(to, from, next) {
          /*eslint-disable-next-line*/
          pageview({ page_title: to.name as string, page_path: to.path });
          next();
        },
      },

      {
        path: "confirm-appointment-details",
        name: "confirm-appointment-details",
        component: lazyLoad("ConfirmAppointmentDetails"),
      },

      {
        path: "choose-appointment",
        name: "choose-appointment",
        component: lazyLoad("ChooseAppointment"),
      },
      {
        path: "register",
        name: "register",
        component: lazyLoad("CreateAccount"),
      },
      {
        path: "pay",
        name: "pay",
        component: lazyLoad("Payment"),
      },

      {
        path: "appointment-booked",
        name: "appointment-booked",
        component: lazyLoad("BookingConfirmed"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const _hsq = (window._hsq = window._hsq || []);

router.beforeEach((to, from, next) => {
  // Store previous and current path
  routerPaths.previous = from.path;
  routerPaths.current = to.path;

  // Trigger page view in HubSpot
  _hsq.push(["setPath", to.path]);
  _hsq.push(["trackPageView"]);
  if (customerDetails.value?.email) {
    updateContactDetails(customerDetails.value.email, {
      lastOnboardingPath: to.path,
    });
  }

  if (from && from.name === "appointment-booked") {
    console.log("Booking has already been completed - redirect aborted.");
    next(false);
    return;
  }
  next();
});

router.onError((error) => {
  logger.error("router onError handler - ", error);
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  logger.log({ router, isChunkLoadFailed });
  router.back();
  //if (isChunkLoadFailed) {
  //router.replace(targetPath);
  //}
});

export default router;
