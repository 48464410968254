import * as Sentry from "@sentry/browser";

export default {
  error(error: string, errorInfo: any) {
    console.log(errorInfo instanceof Error);
    Sentry.withScope((scope) => {
      scope.setTransactionName(error);
      if (errorInfo instanceof Error) {
        Sentry.captureException(errorInfo);
      } else if (typeof errorInfo === "object") {
        if (errorInfo.message) {
          Sentry.captureException(new Error(errorInfo.message));
        } else {
          Sentry.captureException(new Error(error), {
            extra: errorInfo,
          });
        }
      }
    });
    console.error(error, JSON.stringify(errorInfo));
  },
  log(log: any) {
    console.log(JSON.stringify(log));
  },
};
